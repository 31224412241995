import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql, Link } from "gatsby"
import ResultCard from "../components/resultComponents/ResultCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"ilman-nettia"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Katso halvin puhelinliittymä ilman nettiä vuodelle 2020 täältä!"
        desc="Tarvitsetko halpaa puhelinliittymää ilman nettiä? Katso täältä parhaat vaihtoehdot nettittömäksi liittymäksi Telialta, Saunalahdelta, DNA:lta ja Moi mobiililta!"
      />
      <Container>
        <Header1>Valitse halvin puhelinliittymä ilman nettiä</Header1>
        <TextArea>
          Puhelinliittymä ilman nettiä voi olla monelle hyvä vaihtoehto, kun
          puhelinta halutaan käyttää vain sen alkuperäiseen ideaan eli
          puhumiseen ja viestittelyyn. Nykyään on kuitenkin vaikea löytää
          liittymää ilman nettiä ja itseasiassa täysin netittömiä liittymiä ei
          enään ole. Alapuolelta löydät liittymät, jotka sisältävät rajattoman
          erittäin hitaan nettiliittymän ja viesteistä sekä puheluista maksetaan
          käytön mukaan. Nämä ovat tämän hetken parhaat vaihtoehdot kun etsit
          liittymää ilman internettiä.
        </TextArea>
        <Header3>
          Valitse sopiva puhelinliittymä ilman nettiä alapuolelta:
        </Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös seuraaviin vertailuihin:"}>
          <Button link={"/lapselle/"} title={"Puhelinliittymä lapselle"} />
          <Button
            link={"/puhelinliittymavertailu/"}
            title={"Puhelinliittymävertailu"}
          />
          <Button link={"/mobiililaajakaista/"} title={"Mobiililaajakaista"} />
          <Button link={"/prepaid/"} title={"Prepaid"} />
        </RowContainer>
        <Header2>Puhelinliittymä ilman nettiä</Header2>
        <TextArea>
          Toisin kuin monessa muussa maassa, Suomessa on tunnetusti erittäin
          halvat puhelinliittymät. Tämä johtuu osittain siitä, että kilpailua on
          riittävästi ja erilaisia operaattoreita kyllin monta, jotta hinnat
          pysyvät alhaalla. Samalla liittymä ilman nettiä on ajautunut hieman
          turhaksi sillä mobiilidata on hyvin edullista Suomessa vertaillessa
          muihin maihin. Puhelinliittymiä kannattaa jatkuvasti vertailla
          keskenään, sillä uusista asiakkaista käydään erittäin väkevää
          kilpailua operaattoreiden välillä. Koska uusien liittymien määrä ei
          juurikaan Suomessa kasva, pyrkivät monet operaattorit myös
          houkuttelemaan toisen operaattorin asiakkaita itselleen.
        </TextArea>
        <Header2>Liittymiä ja operaattoreita on monenlaisia</Header2>
        <TextArea>
          Teleoperaattorit tarjoavat nykyään erilaisia kännykkäliittymiä.
          Erityisen suosituiksi ovat muodostuneet liittymät, joihin kuuluu
          erilaisia data- ja internetpaketteja. Ihmiset käyttävät
          mobiililaitteillaan yhä enemmän erilaisia internetpalveluita, mikä
          nostaa käyttäjien internetliikenteen määrää. Monet kuluttajat eivät
          enää juurikaan lähetä tekstiviestejä, vaan käyttävät erilaisia
          viestittelyyn pikaviestipalveluita, jotka toimivat internetin
          välityksellä. Monet näistä palveluista, kuten Telegram, WhatsApp ja
          Skype mahdollistavat myös puheluiden soiton. Tällöin puhelut eivät
          kulje normaalin puhelunverkon läpi. Etuna tässä on tietenkin, että
          soittaa voi helposti myös ulkomaille. Liittymä ilman nettiä
          luonnollisesti estää tämän tyyppiset palvelut, niiden toimiessa
          netillä-
        </TextArea>
        <Header3>
          Liittymä ilman nettiä auttaa keskittymään tärkeisiin asioihin
        </Header3>
        <TextArea>
          Viime aikoina yhteiskunnassa on herännyt keskustelua älypuhelimien
          riippuvuutta aiheuttamista vaikutuksista. Monet applikaatiot ovat
          suunniteltu siten, että niiden parissa aika kuluu erittäin nopeasti.
          Yksinkertaisilta tuntuvat pelit saattavat koukuttaa pelaajan
          viettämään huomaamatta hyvinkin pitkiä aikoja. Myös sosiaalinen media
          on aikaa vievää puuhaa. Sisällön tuottamisessa ja selaamisessa voi
          helposti saada tuntejakin kulumaan. Myös videoiden katselemisessa tai
          vaikkapa deitti-applikaatioiden selaamisessa voi helposti vierähtää
          paljon aikaa, mikä tietää myös suurta dataliikennettä ja kasvavaa
          liiketoimintapotentiaalia operaattoreille. Puhelinliittymä ilman
          nettiä ei enään alakaan kuullostaman niin huonolta idealta.
        </TextArea>
        <TextArea>
          Lukuisat ihmiset ovat heränneet älypuhelimien käytön negatiivisiin
          vaikutuksiin, joista suurempina lienevät keskittymishäiriöt ja ajan
          tuhlaaminen. Tämä on johtanut siihen, että pieni ja kasvava osa
          ihmisestä pyrkivät rajoittamaan omaa älypuhelimensa käyttöaikaa,
          jolloin omaa aikaa jää tuottavammalle tekemiselle. Monet ihmiset eivät
          myöskään syystä tai toisesta juurikaan käytä mobiilidataa ja surffaa
          puhelimella internetissä. Tällaisille asiakkaille sopii loistavasti
          puhelinliittymä ilman nettiä, joka ei sisällä lainkaan datapakettia.
          Netittömät liittymät sisältävät myös monia muita etuja kuten
          <ul>
            <li>
              • Puhelinliittymä ilman nettiä auttaa vieroittumaan
              internetin-käytöstä
            </li>
            <li>
              • Puhelinliittymä ilman nettiä onnistuu myös vähentämään puhelimen
              käyttöä
            </li>
            <li>• Puhelinliittymä ilman nettiä on usein halvin vaihtoehto</li>
            <li>
              • Puhelinliittymä ilman nettiä toimii myös vanhan mallisilla
              matkapuhelimilla
            </li>
          </ul>
        </TextArea>
        <Header2>Keneltä puhelinlinliittymä ilman nettiä?</Header2>
        <TextArea>
          Suomessa on käytännössä kolme matkapuhelinoperaattoria, jotka
          tarjoavat matkapuhelinliittymiä kuluttajille. Tässä artikkelissa
          vertaillaan Telian, DNA:n ja Elisan palveluntarjontaa, mistä jokainen
          tarjoaa kuluttajille matkapuhelinliittymiä myös ilman nettiä. Erot
          puhelinliittymien hintojen välillä ovat usein marginaalisia, mutta
          huomioon kannattaa ottaa myös muitakin tekijöitä. Niitä ovat
          esimerkiksi verkon kattavuus ja varmuus sekä asiakaspalvelu.
          Kuluttajan kannattaa myös huomioida, että asiakkaita houkutellakseen
          tarjouskampanjoiden aikaan yhtiöt voivat antaa huomattavia etuja. Myös
          puheluiden määrä vaikuttaa siihen, minkälainen liittymä kannattaa
          itselleen valita.
        </TextArea>
        <Header3>Telia</Header3>
        <TextArea>
          Telia tunnettiin Suomessa ennen Sonerana, kunnes nämä kaksi yhtiötä
          fuusioitiin. Telia oli kaupassa isompana osakkaana, minkä takia
          yhteisyhtiö tunnetaan nykyään vain nimellä Telia. Yhtiön edustus on
          levinnyt kaikkialle Suomeen, ja sen kauppoja, jotka aiemmin tunnettiin
          nimellä Sonera-kauppa, löytyy jokaisesta kaupungista. Tämä lienee yksi
          Telian suurimmista eduista; asiakkaalla on mahdollista keskustella
          osaavan asiakaspalveluhenkilökunnan kanssa myös kasvotusten. Telian
          omista liikkeistä ja kumppanuusliikkeistä on mahdollista myös hoitaa
          muita matkapuhelinasioita, kuten vaikkapa uuden puhelimen ostamisen.
        </TextArea>
        <Header4>Pakettiliittymät</Header4>
        <TextArea>
          Telia on panostanut vahvasti matkapuhelinliittymätarjonnassaan
          erilaisiin paketteihin. Tämä on ymmärrettävää, sillä se tuo lisää
          tulovirtaa yritykselle. Samalla erilaiset paketit saattavat muodostua
          edullisiksi sellaisille asiakkaille, jotka käyttävät puhelintaan
          monipuolisesti ja runsaasti. Sonera jakaa liittymätarjontansa kahden
          komponentin varaan, jotka ovat puhe ja tekstiviestit sekä data, eli
          internetliikenne. Liittymiä on tarjolla kahden tyyppisiä; toisissa
          asiakas maksaa kiinteää kuukausihintaa sovitusta määrästä operaattorin
          yhteyspalveluita, toisissa taas asiakas maksaa ainoastaan siitä
          määrästä, minkä on todella käyttänyt. Puhelinliittymä ilman nettiä on
          lähes aina jälkimmäisen tapainen liittymä.
        </TextArea>
        <Header4>Telia rajaton lite</Header4>
        <TextArea>
          Satunnaiseen käyttöön oivallisin valinta on Rajaton Lite -liittymä,
          joka maksaa 7,90 euroa. Tässä vaihtoehdossa asiakas maksaa puheluista
          ja normiviesteistä hinnaston mukaisen maksun, jonka lisäksi pakettiin
          kuuluu erittäin hidas rajaton nettiyhteys. Seuraavaksi edullisin on
          Rajaton 20 M -liittymä, joka sisältää 250 minuuttia puheluita ja 250
          kappaletta tekstiviestejä. Kyseinen liittymä maksaa 22,90 euroa
          kuussa. Paljon puheluita ja tekstiviestiä käyttävälle voi suositella
          29,90 Rajaton 200 M -liittymää, joka sisältää myös rajattoman netin.
        </TextArea>
        <Header4>Telia Dot</Header4>
        <TextArea>
          Telia pakettimuotoiset liittymät ovat hankalia hahmottaa, eikä
          kuluttajalla ole mahdollista valita liittymää, joka sisältäisi
          rajattomat puhelut, mutta ilman internetiä. Telialla on onneksi myös
          yksi yksinkertainen liittymä: Telia Dot, jossa on rajattomat puhelut
          ja internet. Kyseisessä liittymässä maksetaan vain toteutuneesta
          käytöstä, mutta sillä erotuksella, että laskun loppusumma voi olla
          maksimissaan vain 25 euroa kuussa. Se onkin Telian liittymistä
          suositeltavin sellaiselle, joka ei tarvitse nettiä. Miltei kaikissa
          Telian liittymissä on myös kolmen euron avausmaksu, mutta ei
          määräaikaisuutta.
        </TextArea>
        <Header3>DNA</Header3>
        <TextArea>
          DNA on puhtaasti suomalaisessa omistuksessa oleva matkapuhelin yhtiö,
          joka listautui Helsingin pörssiin muutama vuosi sitten. Tätä ennen
          yhtiö oli yksityisessä omistuksessa. DNA:n matkapuhelinverkko on
          nykyisin hyvin kattava, minkä lisäksi verkkoa ja sen kuuluvuutta
          pyritään jatkuvasti parantamaan. Yhtiöllä on myös kohtalaisen vahva
          edustus ympäri Suomea. Yhtiön näkyvimpinä brändinä toimivat
          DNA-kaupat, jotka matkapuhelinliittymien lisäksi myyvät muita
          tuotteita, kuten matkapuhelimia ja erilaisia matkapuhelintarvikkeita.
          Lisäksi ne toimivat asiakaspalvelupisteinä tarjoten korkealuokkaista
          osaamista matkapuhelin asioissa.
        </TextArea>
        <Header4>DNA liittymät kätevästi netistä</Header4>
        <TextArea>
          DNA:n nettisivut ovat erittäin selkeät. Niiden kautta uuden liittymän
          voi myös tilata erittäin helposti ja vaivattomasti. DNA:n
          matkapuhelinliittymät on jaoteltu selkeästi ja ymmärrettävällä
          tavalla. Mitä enemmän puhelinta käyttää, sen kalliimmaksi se
          muodostuu, mutta samalla yhtiö myy erilaisia liittymäpaketteja, joiden
          avulla kuluttaja voi tehdä matkapuhelinkäytöstään halvempaa ja
          mutkattomampaa. Nettiä vähän tai ei lainkaan käyttäville on
          suositeltavissa kolme eri vaihtoehtoa, jotka ovat DNA Puhe Plus, DNA
          Jämpti ja DNA Rajaton.
        </TextArea>
        <Header4>DNA Puhe Plus</Header4>
        <TextArea>
          <b>DNA Puhe Plus</b> maksaa 7,90 euroa kuussa. Puhelut maksavat 0,07
          euroa per minuutti ja tekstiviestit 0,07 euroa per kappale. Saat siis
          sata viestiä alle yhdellä eurolla. Kyseinen liittymä sopii parhaiten
          niille, jotka eivät käytä nettiä koskaan, ja haluavat maksaa vain ja
          ainoastaan toteutuneesta käytöstä. Mikäli nettiä tulee käytettyä
          satunnaisesti, jolloin tarve hieman nopeammalle netille voi olla
          tarpeellinen, voi kuluttaja valita <b>DNA Jämptin</b>, joka sisältää 1
          Mbt/s rajattoman internetin käytön.
        </TextArea>
        <Header4>DNA rajaton</Header4>
        <TextArea>
          <b>DNA Rajaton</b> on oivallinen valinta sellaiselle, joka ei tarvitse
          liikaa nettiä, mutta käyttää puhelinta muuten runsaasti. Tämä liittymä
          maksaa vain 22,90 euroa per kuukausi, eikä käyttäjän tarvitse liikaa
          miettiä viettääkö puhelimessa liian pitkiä aikoja vai ei. DNA:n
          liittymät ovat siitä loistavia, että ne eivät sisällä lainkaan
          määräaikaisuutta. Liittymän voi siis katkaista milloin vain, eikä
          asiakasta laskuteta enempää.{" "}
          <b>DNA-liittymien avausmaksu on 3,90 euroa</b>, joka laskutetaan aina
          ensimmäisen puhelinlaskun yhteydessä.
        </TextArea>
        <Header3>Elisa</Header3>
        <TextArea>
          Elisa yhdistyi jo yli 10 vuotta sitten Saunalahti-nimisen
          teleoperaattorin kanssa, ja hyvin pitkään Elisa myi rinnakkain sekä
          omia puhelinliittymiään sekä Saunalahden puhelinliittymiä. Saunalahden
          toiminnan sisällyttäminen Elisaan olikin jokseenkin pitkä prosessi,
          koska Saunalahdella oli erittäin hyvä maine kuluttajien joukossa.
          Elisa on sittemmin yksinkertaistanut matkapuhelinliittymä tarjontaansa
          siten, että sillä ei ole enää käytössä Elisa-liittymiä. Kaikki
          puhelinliittymät ovat kulkevat nimellä Saunalahti-puhelinliittymät.
          Yhtiön nettisivut ovat kohtalaisen selkeät ja niiden kautta asiakkaan
          on mahdollista tilata itselleen sopiva liittymä.
        </TextArea>
        <TextArea>
          Saunalahden liittymät ovat suunniteltu sellaisille ihmisille, jotka
          käyttävät paljon nettipalveluita. Miltei kaikki liittymätyypit ovat
          pakettimuotoisia, ja niissä on kiinteä kuukausittainen maksu. Huonona
          puolena tässä on tietenkin se, että asiakas joutuu maksamaan netin
          käytöstä, vaikka hänellä ei olisi juuri mitään käyttöä tälle
          ominaisuudelle. Tämä onkin Elisan tarjonnassa kenties parannettavin
          asia – yhtiö olettaa etukäteen, että kaikki ihmiset tarvitsevat
          internetiä. Yhtiön kannattaisikin laajentaa omaa palvelutarjontaansa
          liittymien suhteen, jotta se onnistuu paremmin palvelemaan
          asiakkaitaan. Elisa tarjoaa myös seuraavia etuja:
          <ul>
            <li>Elisan asiakkaana on mahdollista tilata myös Elisa-viihde</li>
            <li>Elisa tarjoaa erittäin hyvän kuuluvuusalueen</li>
            <li>Elisan asiakaspalvelu toimii tehokkaasti</li>
            <li>Elisa Shopit -liikkeissä voi asioida ympäri maata</li>
          </ul>
        </TextArea>
        <Header4>Saunalahti Tarkka mini</Header4>
        <TextArea>
          Elisan halvin vaihtoehto on <b>Saunalahti Tarkka Mini</b>, joka maksaa
          7,90 euroa kuussa. Laskuttaminen tapahtuu tässä liittymässä käytön
          mukaan, ja siihen kuuluu kohtalaisen nopea internet. Paljon puhuvalla
          ja nettiä juuri lainkaan käyttävälle on Saunalahti Huoleton Mini, joka
          maksaa 19,90 euroa ensimmäiset kuusi kuukautta, jonka jälkeen hinta
          nousee 21,90 euroon per kuukausi. Jos kuluttaja kaipaa kuitenkin
          satunnaiseen käyttöön hieman nopeampaa nettiyhteyttä voi hän valita
          esimerkiksi <b>Saunalahti Huoleton Premiumin</b>, joka sisältää
          ilmaisten puheluiden/viestien lisäksi myös 150 megan netin.
        </TextArea>
        <Header4>S-bonusta liittymistä</Header4>
        <TextArea>
          Saunalahden liittymät ovat täysin pakettivoittoisia ja yhtiö on
          luopunut kuukausimaksuttomista liittymistä. Yhtiö pyrki aikaisemmin
          vahvasti tarjoamaan myös liittymiä, joissa on olematon kuukausimaksu,
          mutta on sittemmin luopunut niistä. Yhtiön liittymissä on myös
          verrattain korkea avausmaksu, joka on 4,90 euroa. Se on selkeästi
          korkeampi maksu kuin muilla operaattoreilla, joten yhtiön kannattaisi
          laskea sitä. Saunalahden liittymistä saa kuitenkin S-bonusta, mikä voi
          olla merkittävä etu niille, jotka keskittävät hankintojaan ja ostojaan
          tietyn ketjun pariin.
        </TextArea>
        <Header2>Miten valita halvin puhelinliittymä ilman nettiä?</Header2>
        <TextArea>
          Kaikkein tärkein kriteeri liittymää valitessa on tietenkin sen
          toimivuus ja luotettavuus. Suomessa on kolme eri matkapuhelinverkkoa,
          jotka toimivat kaupungeissa moitteettomasti. Muualla maassa tilanne on
          kuitenkin tyystin eri. Haja-asutusalueilla kuuluvuus saattaa välillä
          olla olematon tai sitten se vaihtelee. Yhtiöt mainostavat tietenkin
          omaa kuuluvuusaluettaan parhaana ja toimivana, mutta käytännön kokemus
          toteaa kuitenkin, että on erittäin harmittavaa, mikäli puhelin tippuu
          pois verkosta tai jos se ei lainkaan toimi, etenkin sellaisissa
          tilanteissa, joissa tarve olisi kova. Puhelinliittymä ilman nettiä ei
          tarkoita sitä ettetkö tarvitsisi kuuluvuutta puhelimeesi.
        </TextArea>
        <TextArea>
          Osviittaa kuuluvuudesta saa tietenkin operaattoreiden nettisivuilta,
          joissa on olemassa kuuluvuuskartat. Ne löytyvät helposti internetin
          hakukoneista kirjoittamalla kuuluvuuskartan ja operaattorin nimen
          hakukenttään. Kannattaa myös rohkeasti kysellä ihmisiltä, mikä
          liittymä toimii ja millä operaattoreilla on huono kuuluvuus. Liittymää
          ei kannatakaan aivan suoraan vaihtaa, ennen kuin on perehtynyt
          tarkkaan, että puhelin toimii kullakin alueella moitteettomasti.
          Liittymän takaisin vaihtaminen on aina työläs prosessi ja aiheuttaa
          samalla myös kustannuksia. Kysy siis vähintään mökkinaapurilta, mikä
          liittymä on käytössä ennen vaihtoa.
        </TextArea>
        <TextArea>
          Telia, DNA ja Elisa ovat kaikki vahvasti profiloituneet tarjoamaan
          erilaisia pakettiratkaisuja. Nämä paketit suosivat erityisesti paljon
          nettiä käyttäviä. Suurin osa ihmisistä käyttää viestinnässään hyödyksi
          internetverkkoa, jolloin monikaan ei tarvitse puheluita tai
          tekstiviestejä. Erityisesti ikääntyvät ihmiset eivät juuri tarvitse
          puhelintaan muuhun kuin soittamiseen, joten on hieman kurjaa, ettei
          heitä ole huomioitu palveluntarjonnassa sen paremmin. Vaikka nettiä ei
          puhelimeen tarvitsisikaan voi silti miettiä, tarvitseeko nettiä
          ylipäätänsä esimerkiksi kotona tai mökin tietokoneessa.
        </TextArea>
        <TextArea>
          Tässä tapauksessa ei kannata unohtaa, että puhelinta voi käyttää myös
          modeemina, jolloin ylimääräistä kiinteää nettiyhteyttä ei tarvitse
          välttämättä ollenkaan. Tällöin kuluttaja voi helposti säästää rahaa,
          koska päällekkäinen internet-maksua voidaan välttää. Tähän
          tarkoitukseen henkilö tarvitsee usein kuitenkin uuden ja modernin
          älypuhelin, jotka ovat usein kohtalaisen kalliita. Liittymäasioissa
          kannattaa lisäksi huomioida myös asiakaspalvelun laatu. Kannattaa myös
          siis ottaa selville, missä sijaitsee lähin fyysinen
          asiakaspalvelupiste, jossa liittymäasioita voi helposti hoitaa myös
          kasvokkain.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Nettiä vähän käyttävälle asiakkaalle on olemassa kevyt liittymä
          vaihtoehdot, jotka kustantavat kaikilta kolmelta operaattorilta 7,90
          euroa kuukaudessa. Satunnaiseen puhelimen käyttöön voi tämäkin
          vaihtoehto tuntua kalliilta, sillä kuluttaja joutuu maksamaan ilman
          puhelimen käyttääkin kyseistä perusmaksua. Tällaisessa tapauksessa voi
          olla järkevää hankkia prepaid-liittymä. Tämä ei ole puhelinliittymä
          ilman nettiä, mutta usein liittymään voi liittää dataestoja.
        </TextArea>
        <TextArea>
          Paljon puhuvalle on suositeltavaa hankkia <b>Telia Dot -liittymä</b>,
          jossa liittymän maksu ei ylitä 25 euron maksua. Myös DNA Rajaton sopii
          hyvin, jos DNA:n verkko toimii paremmin. Kuluttajan kannattaa
          kuitenkin paketeissa huomioida, että palvelunumeroihin soittaminen
          laskutetaan aina ylimääräisenä.
        </TextArea>
        <Header4>
          Onko puhelinliittymä ilman nettiä nykyään siis täysi mahdottomuus?
        </Header4>
        <TextArea>
          Puhelinliittymä ilman nettiä voi olla vaikeea löytää, sillä lähes
          kaikki operaattorit sisällyttävät liittymiinsä ainakin sen hitaimman
          netin. Mikä liittymän haluaa kuitenkin ilman nettiä esimerkiksi
          <Link to="/lapselle/">lapselle</Link> tai vaikka vain itselle, niin
          tavallisesti liittymiin onnistuu laittamaan esto jolloin internetin
          käytöstä tulee mahdotonta.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { internetType: { in: "2g" } }) {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
